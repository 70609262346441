<template>
  <div class="user-evaluate">
    <div class="e-container">
      <div class="c-content">
        <div class="c-goods">
          <div class="g-img">
            <a href="#">
              <img :src="list.img" alt="" />
            </a>
          </div>
          <div class="g-name">
            <a href="#">
              {{ list.goods_name }}
            </a>
          </div>
          <div class="g-price">
            <strong>¥{{ list.goods_price }}</strong>
          </div>
        </div>
        <div class="c-eva">
          <div class="e-icon">
            &nbsp;
            <el-rate v-model="goods_score" :colors="iconColors"></el-rate>
            <span> {{ goods_score }}分 </span>
          </div>
          <div class="e-checked">
            <el-input
              type="textarea"
              placeholder="写写你的感受吧，一不小心就成了评论高手。"
              v-model="comment"
              maxlength="500"
              show-word-limit
              :rows="5"
              resize="none"
            >
            </el-input>
          </div>
          <div class="e-upload">
            <!-- 
                  name:上传的文件字段名;默认为file;
                  action:必选参数，上传的地址;
                  on-preview:点击文件列表中已上传的文件时的钩子
                  on-remove:文件列表移除文件时的钩子
               -->
            <el-upload
              ref="pictureUpload"
              list-type="picture-card"
              :file-list="fileList"
              multiple
              :auto-upload="false"
              :limt="4"
              action="#"
              :on-change="handleChange"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </div>
      </div>
      <div class="c-publish">
        <div class="p-content">
          <div class="c-btn" @click="ReplyUp()">发表</div>
          <div class="c-txt">
            <el-checkbox v-model="checked">匿名评价</el-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getReplyWay, getReplylistwayApi } from '@/api/reply'

  export default {
    name: "userEvaluate",
    data() {
      return {
        id: 0,
        oid: 0,
        comment: '',
        goods_score: '',
        img: '',
        list: [],
        iconColors: ['#e1251b', '#e1251b', '#e1251b'],
        checked: "",
        hideUpload: false,
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        fileList:[]
      }
    },
    methods: {
      async getReplylistway() { //获取商品评论信息表，初始化数据
        const { data: res } = await getReplylistwayApi({ id: this.id, oid: this.oid })
        this.list = res.result;
      },
      async ReplyUp() { //评论提交
        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("oid", this.oid);
        formData.append("goods_score", this.goods_score);
        formData.append("comment", this.comment);
        formData.append("img", this.img); // 
        let uploadFiles = this.$refs.pictureUpload.uploadFiles;
        let i = 1;
        uploadFiles.forEach(item => {
          formData.append("file" + i, item.raw);
          i++;
        })
        const { data: res } = await getReplyWay(formData);
        console.log(res.message);

        if(res.message!="ok"){
            return this.$message.error('评论提交失败')
        }
        this.$router.go(-1)
      },
      handleRemove(file) { // 相片墙图片删除
        let uploadFiles = this.$refs.pictureUpload.uploadFiles
        for (var i = 0; i < uploadFiles.length; i++) {
          if (uploadFiles[i]['url'] == file.url) {
            uploadFiles.splice(i, 1)
          }
        }
      },
      handleChange(file, fileList) {
        // >=我们想要限制的数量，可以使任意值
        this.hideUpload = fileList.length >= 4;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    },
    created() {
      this.id = this.$route.query.id
      this.oid = this.$route.query.oid
      this.getReplylistway();
    },
  }
</script>

<style lang="less" scoped>
.user-evaluate {
  background: #f9f9f9;
  padding: 40px 20px;
  min-height: 600px;

  .e-container {
    width: 1200px;
    margin: 0 auto;

    .c-content {
      height: 345px;
      background: #fff;
      display: flex;

      .c-goods {
        width: 322px;
        height: 345px;
        box-sizing: border-box;
        border-right: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 40px;

        .g-img {
          width: 100px;
          height: 100px;

          img {
            width: 100px;
            height: 100px;
          }
        }

        .g-price {
          strong {
            font-family: verdana;
            color: #666;
          }
        }
      }

      .c-eva {
        width: 878px;
        height: 345px;
        box-sizing: border-box;
        padding: 40px 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .e-icon {
          display: flex;

          span {
            color: #e1251b;
            margin-top: 1px;
            font-weight: 600;
          }
        }

        .e-checked {
          width: 650px;
          margin: 20px 0px;

          /deep/ .el-textarea__inner {
            font-size: 12px;
          }
        }

        /deep/ .hide .el-upload--picture-card {
          display: none;
        }
      }
    }

    .c-publish {
      height: 100px;
      margin-top: 20px;
      background: #fff;
      position: relative;

      .p-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;

        .c-btn {
          width: 200px;
          height: 48px;
          background: #e1251b;
          border-radius: 5px;
          font-size: 18px;
          color: #fff;
          text-align: center;
          line-height: 48px;
        }

        .c-txt {
          line-height: 48px;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>